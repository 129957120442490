@font-face {
  font-family: "GT Walsheim Pro";
  src: local("GT Walsheim Pro Regular"), local("GT-Walsheim-Pro-Regular"),
    url("../assets/font/GTWalsheimPro-Regular.woff2") format("woff2"),
    url("../assets/font/GTWalsheimPro-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

* {
  font-family: "GT Walsheim Pro";
}

html {
  -webkit-tap-highlight-color: transparent;
}

body {
  padding: 0;
  margin: 0;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.txt-capitalize {
  text-transform: capitalize;
}
.d-inline-block {
  display: inline-block;
}
.back-grad-title {
  background-image: linear-gradient(90deg, #4595ff, #007dfe);
  border-bottom-left-radius: 65px;
  border-bottom-right-radius: 65px;
  margin-bottom: 40px;
  padding: 200px 20px 80px;
  max-width: 1600px;
  margin: 0 auto;

  @media only screen and (max-width: 767px) {
    padding-top: 170px;
    padding-bottom: 60px;
  }

  .pricing-main-title {
    font-size: 65px;
    text-align: center;
    color: white;

    @media only screen and (max-width: 767px) {
      font-size: 30px;
    }
  }
}

.CardField-expiry {
  transform: none !important;
}

.hl_cta_wrap {
  position: relative;
  overflow: hidden;
  width: max-content;
  height: 100%;
  padding: 12px 20px;
  border: 1px solid currentColor;
  border-radius: 14px;
  transition: 0.5s ease-in-out;
  z-index: 1;
  background-color: transparent;
  color: #007dff;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #fff !important;
    background-color: #007dff !important;
  }
}

.errorInput {
  border-color: red;
}

.hl_cta_wrap-white {
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: 0.5s ease-in-out;
    z-index: -1;
  }
}

button {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.hl_cta_wrap-white {
  &::after {
    left: -50px;
    border-left: 50px solid transparent;
    border-top: 80px solid #ffffff;
    transform: translateX(100%);
  }

  &::before {
    right: -50px;
    border-right: 50px solid transparent;
    border-bottom: 80px solid #fff;
    transform: translateX(-100%);
  }

  &:hover {
    color: #007dff !important;

    &::after {
      transform: translateX(49%);
    }

    &::before {
      transform: translateX(-49%);
    }
  }
}

:where(.css-dev-only-do-not-override-k7429z).ant-notification
  .ant-notification-notice
  .ant-notification-notice-message {
  height: max-content;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.overly-loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1905px;
  width: 100%;
  min-height: 100%;
  height: 800px;
  background-color: rgba(255, 255, 255);
  z-index: 100;
  top: 0;
  left: 0;
}

.spinner {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.session_expired_modal {
  .ant-modal-body {
    .ant-modal-confirm-body-wrapper {
      .ant-modal-confirm-body {
        justify-content: space-around;

        span {
          display: none;
        }
      }
    }
  }
}

.session-Model {
  .session-modal-logo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;

    .bd-logo {
      border: 1px solid;
      border-radius: 50%;
      border-color: #ff6163;
      height: 77px;
      width: 77px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      svg {
        font-size: 70px;
        color: #ff6163;
      }
    }
  }

  .session-modal-text {
    text-align: center;

    h3 {
      margin-bottom: 0;
      font-size: 30px;
    }

    p {
      font-size: 16px;
    }
  }

  .session-modal-btn {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 35px;
  }
}

.errorFelid {
  border-color: red !important;
}

.overly-loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1905px;
  width: 100%;
  min-height: 100%;
  height: 800px;
  background-color: rgba(255, 255, 255, 0.425);
  z-index: 100;
  top: 0;
  left: 0;
}

// Language Css

.theme_dir__rtl {
  .header-main-section {
    .header {
      .header-df {
        .header-main-logo {
          margin-left: 0px !important;
          margin-right: 30px;
        }
      }
    }
  }

  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .right-contant {
            .banner-img {
              transform: rotateY(180deg);
            }
          }

          .left-contant {
            .head-input {
              .desktop-input-nd {
                border-radius: 50px 0px 0px 50px;
                border-right: 1px solid gray;
                border-left: unset;
                width: 100%;
              }

              .react-tel-input {
                input {
                  border-radius: 0px 50px 0px 0px !important;
                  padding-left: 30px;
                }

                border-radius: 50px 50px 50px 50px;
              }

              .desktop-input {
                .flag-dropdown {
                  .selected-flag {
                    padding: 0px 17px 0px 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .footer {
    .footer-container {
      .footer-section {
        .footer-inner-container {
          .footer-logo {
            margin-left: 0px;
            margin-right: 50px;
          }
        }
      }
    }
  }

  .wrap-top-modal {
    .body-dash-modal {
      .dashboard-find-number {
        .dashboard-locate {
          input {
            padding-left: 0px;
            padding-right: 75px;
            text-align: right;
          }
        }

        label {
          span {
            &:last-child {
              margin-left: 0px;
              margin-right: 1rem;
            }
          }
        }
      }

      .dashboard-message {
        label {
          span {
            &:last-child {
              margin-left: 0px;
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }

  .dashboard-main-section {
    .container {
      .dashboard-find-number {
        label {
          span {
            &:last-child {
              margin-left: 0px;
              margin-right: 1rem;
            }
          }
        }

        .dashboard-locate {
          input {
            padding-left: 0px;
            padding-right: 75px;
            text-align: right;
          }

          .input-suffix {
            right: auto;
            left: 5px;
          }

          .flag-dropdown {
            border-radius: 0px 15px 15px 0px;
          }
        }
      }

      .setting-find-number {
        .setting-billing-heading {
          span {
            &:last-child {
              margin-left: 0px;
              margin-right: 1rem;
            }
          }
        }
      }

      .dashboard-history {
        .dashboard-history-title {
          label {
            span {
              &:last-child {
                margin-left: 0px;
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }
  }

  .login-modal-section {
    .wrap-top-modal {
      .body-dash-modal {
        .submit-btn-modal {
          button {
            box-shadow: unset;
          }
        }

        .dashboard-find-number {
          .dashboard-locate {
            .flag-dropdown {
              border-radius: 0px 15px 15px 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .theme_dir__rtl {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .left-contant {
            .input-suffix {
              right: auto;
              top: 5px;
              left: 5px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .theme_dir__rtl {
    .footer {
      .footer-container {
        .footer-section {
          .footer-inner-container {
            .footer-logo {
              margin-left: 0px;
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .theme_dir__rtl {
    .header-main-section {
      .header {
        .header-df {
          .header-main-logo {
            margin-right: 15px;
          }
        }
      }
    }

    .bg-grad-section {
      .Locate-Any-Phone {
        .wid-home-banner {
          .df-first-section {
            .left-contant {
              .heading-title {
                max-width: 100%;
                max-width: 100%;
              }

              padding: 0px 0px;
            }
          }
        }
      }
    }
  }
}

// New Css

.theme_dir__rtl {
  .react-tel-input {
    .selected-flag {
      &:hover {
        background: transparent;
      }

      &:focus {
        background: transparent;
      }
    }

    .country-list {
      .flag {
        margin-left: 7px;
        margin-right: 0px;
      }
    }
  }

  .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .left-contant {
          .input-suffix {
            right: auto;
            left: 0px;
          }

          .head-input {
            .desktop-input {
              .flag-dropdown {
                &::after {
                  right: auto;
                  left: 0px;
                  right: auto;
                  left: -40px;
                }
              }
            }

            .mobile-input {
              .flag-dropdown {
                &::after {
                  right: auto;
                  left: 0px;
                  right: auto;
                  left: -10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .left-contant {
            .head-input {
              .react-tel-input {
                input {
                  border-radius: 50px 50px 50px 50px !important;
                  padding-left: 0;
                  padding-right: 55px !important;
                  text-align: right;
                  direction: ltr;
                }
              }
              .big_city_dial {
                width: 120px;
                .phone-input-field {
                  padding-right: 60px !important;
                  padding-left: 6px !important;
                  @media only screen and (max-width: 767px) {
                    padding-right: 53px !important;
                  }
                }
                .flag-dropdown {
                  .selected-flag {
                    padding: 0px 11px 0px 0px;
                  }
                }
              }
              .three_city_dial {
                .phone-input-field {
                  padding-right: 55px !important;
                  padding-left: 5px !important;
                  @media only screen and (max-width: 767px) {
                    padding-right: 50px !important;
                  }
                }
                .flag-dropdown {
                  .selected-flag {
                    padding: 0px 11px 0px 0px;
                  }
                }
              }
              .medium_city_dial {
                @media screen and (max-width: 767px) {
                  width: 85px !important;
                  flex: 0 0 85px !important;
                }
                .phone-input-field {
                  padding-right: 57px !important;
                  @media only screen and (max-width: 767px) {
                    padding-right: 52px !important;
                  }
                }
                .flag-dropdown {
                  .selected-flag {
                    padding: 0px 13px 0px 0px;
                  }
                }
              }
              .small_city_dial {
                @media screen and (max-width: 767px) {
                  width: 75px !important;
                  flex: 0 0 75px !important;
                }
                width: 90px;
                .phone-input-field {
                  padding-right: 60px !important;
                  @media only screen and (max-width: 767px) {
                    padding-right: 50px !important;
                  }
                }
                .flag-dropdown {
                  .selected-flag {
                    padding: 0px 13px 0px 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.react-tel-input {
  .flag-dropdown.open {
    .selected-flag {
      background: transparent;
    }
  }
}

@media only screen and (max-width: 767px) {
  .theme_dir__rtl {
    .bg-grad-section {
      .Locate-Any-Phone {
        .wid-home-banner {
          .df-first-section {
            .left-contant {
              .head-input {
                .desktop-input {
                  .flag-dropdown {
                    .selected-flag {
                      padding: 0px 10px 0px 0px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .left-contant {
          .head-input {
            .desktop-input {
              .flag-dropdown {
                &:hover {
                  background: transparent !important;
                }
              }
            }

            .mobile-input {
              .flag-dropdown {
                &:hover {
                  background: transparent !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .react-tel-input {
    .flag-dropdown.open {
      .selected-flag {
        background: transparent;
      }
    }
  }
}

// New Language Css[big strings]

.bg-grad-section {
  .home-special-offer {
    .specialOffer-container {
      .special-offerbox-partition {
        .desc-special-span {
          margin-bottom: 100px;
        }
      }
    }
  }
}

.bts__language_wrap {
  .footer {
    .footer-container {
      .footer-section {
        .footer-inner-container {
          .df-footer {
            .footer-right-contan {
              .df-right-footer-menu {
                .legal-info {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.footer {
  .footer-container {
    .footer-section {
      .footer-inner-container {
        .df-footer {
          .footer-right-contan {
            .df-right-footer-menu {
              .legal-menus {
                ul {
                  li {
                    &:not(:last-child) {
                      margin-bottom: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .bg-grad-section {
    .home-special-offer {
      .specialOffer-container {
        .special-offerbox-partition {
          .desc-special-span {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .bts__language_wrap {
    .footer {
      .footer-container {
        .footer-section {
          .footer-inner-container {
            .footer-bottom-contant {
              .footer-bottom-flex {
                flex-wrap: wrap;

                .tracelo-footer {
                  width: 100%;
                  text-align: center;
                  margin-bottom: 10px;
                }

                .policies-footer {
                  width: 100%;
                  text-align: center;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 675px) {
  .bts__language_wrap {
    .footer {
      .footer-container {
        .footer-section {
          .footer-inner-container {
            .footer-bottom-contant {
              .footer-bottom-flex {
                .policies-footer {
                  > div {
                    width: 100%;
                    margin-bottom: 8px;
                  }

                  flex-wrap: wrap;

                  .tems-policy {
                    margin-bottom: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .footer {
    .footer-container {
      .footer-section {
        .footer-inner-container {
          .footer-bottom-contant {
            .footer-bottom-flex {
              .policies-footer {
                .cookies-policy {
                  p {
                    &::after {
                      all: unset;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Language Css

@media only screen and (max-width: 435px) {
  .bts__language_wrap {
    .login-modal-section {
      .login-modal-title {
        width: calc(100% - 30px);
        box-sizing: border-box;
        padding: 10px 20px;
      }
    }
  }
}

.billing-form {
  .ant-select {
    span.ant-select-selection-item {
      color: #868686;
    }
  }
}

// New Footer Css

.footer {
  .footer-container {
    .footer-section {
      .footer-inner-container {
        .df-footer {
          .footer-right-contan {
            .df-right-footer-menu {
              .legal-info {
                margin-right: 10px;
              }

              .legal-menus {
                ul {
                  li {
                    &:not(:last-child) {
                      margin-bottom: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .footer {
    .footer-container {
      .footer-section {
        .footer-inner-container {
          .footer-bottom-contant {
            .footer-bottom-flex {
              flex-wrap: wrap;

              .tracelo-footer {
                text-align: center !important;
              }

              .tracelo-footer {
                width: 100%;
                text-align: center;
                margin-bottom: 10px;
              }

              .policies-footer {
                width: 100%;
                text-align: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .footer {
    .footer-container {
      .footer-section {
        .footer-inner-container {
          .footer-bottom-contant {
            .footer-bottom-flex {
              .policies-footer {
                flex-wrap: wrap;

                > div {
                  width: 100%;
                  margin-bottom: 8px;
                }

                .tems-policy {
                  margin-bottom: 0px;
                }

                .cookies-policy {
                  p {
                    &::after {
                      all: unset !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Faqs Css

@media only screen and (max-width: 767px) {
  .bg-grd-section {
    .frequently-asked-question {
      .question-collapse {
        .collapse-box {
          padding: 15px 10px;

          .collapse-box-df-title {
            .collapse-box-title {
              p {
                font-size: 16px !important;
                line-height: 1.2;
              }
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .login-modal-section {
    .login-modal-title {
      p {
        direction: ltr;
      }
    }
  }

  #cvv-form {
    input#cvv {
      text-align: right;
    }
  }
}

.no-scroll {
  overflow: hidden !important;
  height: 100% !important;
  position: fixed !important;
  width: 100% !important;
}

// Footer Bottom Css

div#root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  min-height: 100vh;
}

#root:has(:not(.footer)) div.footer {
  margin-top: auto;
}

// New Extrenal Css
.theme_dir__rtl.login_btn__wrapper {
  button.ant-modal-close {
    left: 5% !important;
    right: auto !important;
    width: auto !important;
  }
}

.theme_dir__rtl.location_modal {
  button.ant-modal-close {
    left: 5% !important;
    right: auto !important;
    width: auto !important;
  }
}

.theme_dir__rtl.ant-dropdown {
  ul.ant-dropdown-menu {
    li.ant-dropdown-menu-item {
      span.ant-dropdown-menu-title-content {
        a {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .ant-modal-body {
    .login-modal-section {
      .wrap-top-modal {
        .google-button-login {
          button.login-with-google-btn {
            background-position: calc(100% - 15px) center;
          }
        }
      }
    }
  }
}

button.ant-modal-close {
  &:hover {
    background-color: transparent !important;
  }
}

.theme_dir__rtl {
  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .right-contant {
            margin-left: 0px;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.theme_dir__rtl {
  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .left-contant {
            .head-input {
              .react-tel-input {
                input {
                  padding: 0px 54px 0px 0px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
